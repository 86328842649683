
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as gallery_oldOKS8nDQMVeMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/gallery_old.vue?macro=true";
import { default as galleryylaXRFNemvMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/gallery.vue?macro=true";
import { default as garmentsuHBMyh1SHbMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/garments.vue?macro=true";
import { default as inboxvwYtJ1eh1bMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/inbox.vue?macro=true";
import { default as indexWJU4JTM0lxMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/index.vue?macro=true";
import { default as index2CCknKz09eHMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/index2.vue?macro=true";
import { default as locations7hVC6hQcfmMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/locations.vue?macro=true";
import { default as _91id_9387iPG2kt77Meta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/[id].vue?macro=true";
import { default as indexUDG5Kz7AxsMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/index.vue?macro=true";
import { default as index2vpUe2dFKqxMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/index2.vue?macro=true";
import { default as index5QYNNDADTDwMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/index5.vue?macro=true";
import { default as Index555nPEyL6vfwSMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/Index555.vue?macro=true";
import { default as oldz6FEZm102aMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/old.vue?macro=true";
import { default as reelNn3FlAwjEhMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/reel.vue?macro=true";
import { default as scenesvxKWVCBwEdMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/scenes.vue?macro=true";
import { default as indexecnE64ifSHMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings/index.vue?macro=true";
import { default as membersljl1a6VvUbMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings/members.vue?macro=true";
import { default as notifications0AD3bax7XUMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings/notifications.vue?macro=true";
import { default as settingsvYpvXqiKCPMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings.vue?macro=true";
import { default as users60L4WGRXnMMeta } from "/home/runner/work/getgrain/getgrain/packages/app/app/pages/users.vue?macro=true";
export default [
  {
    name: "gallery_old",
    path: "/gallery_old",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/gallery_old.vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/gallery.vue")
  },
  {
    name: "garments",
    path: "/garments",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/garments.vue")
  },
  {
    name: "inbox",
    path: "/inbox",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/inbox.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/index.vue")
  },
  {
    name: "index2",
    path: "/index2",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/index2.vue")
  },
  {
    name: "locations",
    path: "/locations",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/locations.vue")
  },
  {
    name: "models-id",
    path: "/models/:id()",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/[id].vue")
  },
  {
    name: "models",
    path: "/models",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/index.vue")
  },
  {
    name: "models-index2",
    path: "/models/index2",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/index2.vue")
  },
  {
    name: "models-index5",
    path: "/models/index5",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/index5.vue")
  },
  {
    name: "models-Index555",
    path: "/models/Index555",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/Index555.vue")
  },
  {
    name: "models-old",
    path: "/models/old",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/models/old.vue")
  },
  {
    name: "reel",
    path: "/reel",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/reel.vue")
  },
  {
    name: "scenes",
    path: "/scenes",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/scenes.vue")
  },
  {
    name: settingsvYpvXqiKCPMeta?.name,
    path: "/settings",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings.vue"),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings/index.vue")
  },
  {
    name: "settings-members",
    path: "members",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings/members.vue")
  },
  {
    name: "settings-notifications",
    path: "notifications",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/settings/notifications.vue")
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/home/runner/work/getgrain/getgrain/packages/app/app/pages/users.vue")
  }
]