<script setup>
const route = useRoute();
const appConfig = useAppConfig();
const { isHelpSlideoverOpen, isChatModalOpen, isSettingModalOpen } =
  useDashboard();
</script>
<template>
  <UDashboardLayout>
    <UDashboardPanel
      :width="250"
      :resizable="{ min: 200, max: 300 }"
      collapsible
    >
      <UDashboardNavbar class="!border-transparent" :ui="{ left: 'flex-1' }">
        <template #left>
          <LogoReplaca class="w-full p-12" />
        </template>
      </UDashboardNavbar>

      <UDashboardSidebar>
        <template #header>
          <UButton
            color="gray"
            variant="main"
            square
            @click="isChatModalOpen = true"
          >
            <UIcon
              dynamic
              name="i-mingcute-ai-line"
              class="w-5 h-5 text-white"
            />
            Replaca Agent
          </UButton>
        </template>
        <UDashboardSidebarLinks
          :links="links"
          :ui="{
            badge: {
              container: '!my-4',
              base: 'gradient-wrapper dark:text-gray-900 !py-0.5 !px-2 !text-[9px] !font-bold !italic',
            },
          }"
        />
      </UDashboardSidebar>
    </UDashboardPanel>
  </UDashboardLayout>
</template>

<style>
.gradient-border {
  background-image: linear-gradient(#191a1d, #191a1d),
    linear-gradient(279.06deg, #ff9a9a, #c296ff 52.08%, #aaedfd);
  border: double 1px transparent;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  padding: 10px;
}

.gradient-wrapper {
  background: linear-gradient(279.06deg, #ff9a9a, #c296ff 52.08%, #aaedfd);
  color: #000;
  padding: 10px 20px;
}
</style>
