import { createSharedComposable } from "@vueuse/core";

const _useDashboard = () => {
  const route = useRoute();
  const router = useRouter();
  const isHelpSlideoverOpen = ref(false);
  const isNotificationsSlideoverOpen = ref(false);
  const isGarnmentSlideoverOpen = ref(false);
  const isLocationSlideoverOpen = ref(false);

  const isOnboardOpen = ref(false);

  const isChatSlideoverOpen = ref(false);

  const isChatModalOpen = ref(false);

  const isScenesSlideoverOpen = ref(false);

  const isSettingModalOpen = ref(false);

  defineShortcuts({
    "g-h": () => router.push("/"),
    "g-i": () => router.push("/inbox"),
    "g-u": () => router.push("/users"),
    "g-s": () => router.push("/settings"),
    "?": () => (isHelpSlideoverOpen.value = true),
    n: () => (isNotificationsSlideoverOpen.value = true),
  });

  watch(
    () => route.fullPath,
    () => {
      isHelpSlideoverOpen.value = false;
      isNotificationsSlideoverOpen.value = false;
    }
  );

  return {
    isHelpSlideoverOpen,
    isNotificationsSlideoverOpen,
    isGarnmentSlideoverOpen,
    isOnboardOpen,
    isScenesSlideoverOpen,
    isSettingModalOpen,
    isChatModalOpen,
    isLocationSlideoverOpen,
    isChatSlideoverOpen,
  };
};

export const useDashboard = createSharedComposable(_useDashboard);
