<script setup>
const model = defineModel();
import CountUp from "vue-countup-v3";
const loadingStripe = ref(true);
const disabledSubmit = ref(true);
const options = { mode: "billing" };
const loader = "auto";
const totalPayment = ref(1);
const totalPaymentStart = ref(1);
const totalTax = ref(0);
const appearance = {
  variables: {
    colorPrimary: "#fff",
    colorTextPlaceholder: "#606062",
    colorBackground: "#2E2E32",
    colorText: "#F2F2F2",
    colorDanger: "#df1b41",
    fontFamily: "Ideal Sans, system-ui, sans-serif",
    fontSizeBase: "12px",
    borderRadius: "8px",
    spacingUnit: "2px",
  },
  labels: "floating",
  theme: "night",
};
const { stripe } = await useClientStripe();
let elements;

const props = defineProps({
  plan: Object,
  isYearly: Boolean,
});

const total2 = 29;
totalPayment.value = total2 * 12;
totalPaymentStart.value = total2 * 12;

async function getTax(address) {
  const taxdata = await $fetch("/api/get-taxes", {
    method: "POST",
    body: JSON.stringify({ address, amount: 29 * 12 * 100 }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  // alert(JSON.stringify(taxdata));
  totalTax.value = taxdata.tax_amount_exclusive / 100;
  totalPayment.value = taxdata.amount_total / 100;
}

async function initializeStripe() {
  // Make a request to your server to get the client secret
  /*const response = await fetch("/api/create-payment-intent", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const { clientSecret, total } = await response.json();*/

  /*const { clientSecret, total } = await $fetch("/api/create-payment-intent", {
    method: "POST",
    body: JSON.stringify({ planId: props.plan.id, planYearly: props.isYearly }),
    headers: {
      "Content-Type": "application/json",
    },
  });*/

  //elements = stripe.value.elements({ clientSecret, loader, appearance });

  const options2 = {
    mode: "subscription",
    amount: 0,
    currency: "usd",
    // Fully customizable with appearance API.
    appearance,
  };

  elements = stripe.value.elements(options2);

  /*const linkAuthenticationElement = elements.create("linkAuthentication");
  linkAuthenticationElement.mount("#linkAuthentication");

*/

  /*const expressCheckoutOptions = {
    buttonType: {
      applePay: "buy",
      googlePay: "buy",
      paypal: "buynow",
    },
    buttonTheme: {
      paypal: "black",
    },
    buttonHeight: 40,
  };*/

  /*const expressElement = elements.create(
    "expressCheckout",
    expressCheckoutOptions
  );
  expressElement.mount("#express-checkout-element");*/

  const paymentElement = elements.create("payment");
  paymentElement.mount("#payment-element");

  const addressElement = elements.create("address", options);
  addressElement.mount("#address-element");

  addressElement.on("change", async (event) => {
    if (event.complete) {
      // Extract potentially complete address
      const address = event.value.address;
      //alert(JSON.stringify(address));
      await getTax(address);
    }
  });

  disabledSubmit.value = false;
  loadingStripe.value = false;
  //totalPayment.value = total;
  // totalPaymentStart.value = total;
}
onMounted(() => {
  initializeStripe();
});
</script>
<template>
  <div
    class="rounded-xl relative flex h-full w-full items-stretch overflow-hidden shadow-[0px_14px_34px_#000000]"
  >
    <div class="w-[40%] p-4">
      <UButton
        icon="i-heroicons-arrow-left"
        color="gray"
        variant="ghost"
        label="Back to all plans"
        @click="model = false"
      />
      <UPricingCard
        v-bind="plan"
        :ui="{
          ring: 'ring-0',
          background: 'dark:bg-transparent',
          highlight: '',
        }"
        :price="isYearly ? plan.price.year : plan.price.month"
        discount=""
        button=""
        :cycle="isYearly ? '/month' : '/month'"
        orientation="vertical"
        align="bottom"
      >
        <template #description>
          <div
            class="relative mt-[4px] min-h-10 text-[12px] font-normal leading-3 text-gray-300"
          >
            <div class="flex h-[12px] items-center justify-between">
              <div class="flex items-center">
                <span>Tax</span>

                <UTooltip
                  :ui="{ base: 'py-3 h-10' }"
                  text="Tax is determined by your billing address."
                  :popper="{ arrow: true, placement: 'top' }"
                  ><UIcon
                    name="i-heroicons-information-circle"
                    class="w-3 h-3 ml-2 inline-block"
                /></UTooltip>
              </div>
              <div>
                <count-up
                  :duration="0.5"
                  :options="{ prefix: '$' }"
                  :start-val="0.0"
                  :end-val="totalTax"
                ></count-up>
              </div>
            </div>
            <div
              class="gradient-border -mx-2 mb-[9px] mt-[7px] !border-b-0 !p-0 !border-l-0 !border-r-0"
            ></div>
            <div class="flex justify-between">
              <div>Total due today</div>
              <div class="font-medium text-white">
                <count-up
                  :duration="0.5"
                  :options="{ prefix: '$' }"
                  :start-val="totalPaymentStart"
                  :end-val="totalPayment"
                ></count-up>
              </div>
            </div>
          </div>
        </template>
      </UPricingCard>

      <p class="text-[10px] text-[#A6A9B8] p-8 pb-2 pt-0">
        By providing your payment information and clicking “Confirm
        Purchase/Upgrade Plan”, you agree you will be charged the amount listed
        above and your subscription will automatically renew until you cancel
        (price subject to change). Cancel anytime via Account Settings or
        Customer Support. You also agree to the GRAIN AI
        <a
          class="underline"
          href="/terms-of-use/"
          target="_blank"
          rel="noreferrer"
          >Terms of Service</a
        >
        and
        <a
          class="underline"
          href="/privacy-policy/"
          target="_blank"
          rel="noreferrer"
          >Privacy Policy.</a
        >
      </p>
    </div>
    <div
      class="flex flex-1 relative flex-col items-center bg-[#121316] shadow-[0_14px_34px_rgba(0,0,0,1)] p-10"
    >
      <Transition>
        <form
          @submit="handleStripePayment"
          class="container relative flex h-full flex-col justify-start gap-2 p-[35px] pb-[20px]"
          v-show="!loadingStripe"
        >
          <h4 class="text-zinc-100 text-[18px] font-bold leading-none">
            Payment Details
          </h4>
          <h5
            class="mb-[6px] text-[12px] font-normal leading-none text-[#9B9EAC]"
          >
            Card Details
          </h5>
          <div id="payment-element"></div>
          <h5
            class="mb-[6px] mt-[15px] text-[12px] font-normal leading-none text-[#9B9EAC]"
          >
            Billing Address
          </h5>
          <div id="address-element" class="mb-4"></div>
          <UButton label="Confirm Purchase" variant="main" block />
        </form>
      </Transition>

      <div
        v-show="loadingStripe"
        class="absolute inset-0 flex items-center justify-center transition-opacity"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="none"
          viewBox="-15 -15 30 30"
          class="animate-spin"
        >
          <circle
            cx="0"
            cy="0"
            r="13"
            stroke="#686878"
            stroke-width="4"
          ></circle>
          <circle
            cx="0"
            cy="0"
            r="13"
            stroke="#C296FF"
            stroke-width="4"
            stroke-linecap="round"
            stroke-dasharray="81.68140899333463"
            stroke-dashoffset="61.26105674500097"
            transform="rotate(-90)"
          ></circle>
        </svg>
      </div>
    </div>
  </div>
</template>
