export default defineAppConfig({
  ui: {
    notification: {
      // Show toasts at the top right of the screen
      avatar: { size: "lg" },
    },
    button: {
      variant: {
        main: "gradient-border",
        full: "gradient-wrapper",
      },
    },
    primary: "woodsmoke",
    gray: "woodsmoke",
    tooltip: {
      default: {
        openDelay: 500,
      },
    },
  },
});
