<script setup>
//import { CopilotKit } from "@copilotkit/vue-core";
const colorMode = useColorMode();
//const { getImages } = useFile();

/*const color = computed(() =>
  colorMode.value === "dark" ? "#111827" : "white"
);*/

if (process.client) {
  const { initializeWebSocket } = useWebSocket();
  initializeWebSocket();
}

const color = computed(() => (colorMode.value === "dark" ? "#000" : "white"));

colorMode.preference = "dark";

useHead({
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { key: "theme-color", name: "theme-color", content: color },
  ],
  link: [{ rel: "icon", href: "/favicon.ico" }],
  htmlAttrs: {
    lang: "en",
  },
});

const title = "GRAIN AI SAAS";
const description = "GRAIN AI SAAS is a powerful AI tool for your business.";

useSeoMeta({
  title,
  description,
  ogTitle: title,
  ogDescription: description,
  ogImage: "https://dashboard-template.nuxt.dev/social-card.png",
  twitterImage: "https://dashboard-template.nuxt.dev/social-card.png",
  twitterCard: "summary_large_image",
});
//await getImages();
</script>

<template>
  <div>
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <UNotifications
      :ui="{ container: 'notification-stack space-y-0 overflow-visible' }"
    />
    <UModals />
  </div>
</template>

<style>
.notification-stack {
  top: 4rem;
  right: 1rem;
  z-index: 9999;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
}

.notification-stack > div {
  position: absolute;
  width: 300px;
  bottom: 50px;
  transform: translateY(0);
  transition: all 0.3s ease;
  z-index: 9;
}

.notification-stack > div:nth-child(2) {
  transform: translateY(10px) scale(0.98);
  opacity: 0.9;
  z-index: 1;
}

.notification-stack > div:nth-child(3) {
  transform: translateY(20px) scale(0.96);
  opacity: 0.8;
  z-index: 0;
}

.notification-stack > div:nth-child(n + 4) {
  transform: translateY(20px) scale(0.96);
  opacity: 0.8;
  z-index: 0;
}

*:not(input, textarea) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-active {
  transition: opacity 0.6s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.slideUp-enter-active {
  transition: transform 0.4s ease-in-out;
}

.slideUp-leave-active {
  transition: transform 0.2s ease-in-out;
}

.slideUp-enter-from,
.slideUp-leave-to {
  transform: translate(-50%, 100%);
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
